const Links = [
  {
    label: 'Início',
    url: '/',
  },
  {
    label: 'Sobre',
    url: '/about',
  },
];

export default Links;
