const links = [
  {
    label: 'Github',
    url: 'https://github.com/ncesar',
  },
  {
    label: 'Linkedin',
    url: 'https://linkedin.com/in/cesarnascimentoo',
  },
  {
    label: 'Instagram',
    url: 'https://instagram.com/ncesardev',
  },
  {
    label: 'Youtube',
    url: 'https://youtube.com/c/ncesar',
  },
];

export default links;
